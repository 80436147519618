/* ==========================================================================
   
   PAGE HERO

========================================================================== */
.hero {
  position: relative;
  width: 100%;
  transition: all 0.4s ease-in-out;
  margin-bottom: 50px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
  }

  @include media-breakpoint-up(xxxl) {
    min-height: 600px;
  }

  &.hero--home {

    &:before {
      content: '';
      @include z-index(heroGradient);

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #fff, #fff 10%, rgba(255, 255, 255, 0.3) 100%);

      @include media-breakpoint-up(md) {
        background: linear-gradient(90deg, #fff, #fff 5%, rgba(255, 255, 255, 0) 100%);  
      }
    }

    &:after {
      content: '';
      width: 1px;
      height: 60px;
      position: absolute;
      @include z-index(heroInner);
      left: 5vw;
      top: calc(100% - 30px);
      background: $brand;

      @include media-breakpoint-up(md) {
        height: 120px;
        top: calc(100% - 60px);
      }
    }
  }

  &.hero--block {
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      z-index: 1;
    }

    .title {
      text-transform: initial;

      @include media-breakpoint-up(md) {
        max-width: 100%;
      }

      @include media-breakpoint-up(xxxl) {
        max-width: 100%;   
      }
    }

    .hero-content {

      @include media-breakpoint-up(xl) {
        padding-top: 180px;
        padding-bottom: 180px;
      }

      p {
        color: $text-color; 
      }

      .sub-title {
        color: $brand;
      }

      .btn--mobile-special {

        @include media-breakpoint-down(sm) {  
          margin-left: -15vw;
          padding-left: 15vw;
        }

      }

    }

  }

  .hero-inner {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .hero-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }

    > .bg {

      position: absolute;
      right: 0;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: right center;

      width: 100%;
      height: 100%;
      opacity: 0;
      transform: translateX(30px);

      &.loaded {
        animation: heroBg 2s cubic-bezier(0.02, 0.5, 0.99, 1) forwards;
      }

      &.mobile {
        @include media-breakpoint-up(xl) {
          display: none;
        }
      }

      &.desktop {
        @include media-breakpoint-down(lg) {
          display: none;
        }

      }

    }

  }

  .hero-content {
    position: relative;
    @include z-index(heroInner);
    transition: all 0.4s ease-in-out;

    padding-top: 80px;
    padding-bottom: 80px;

    @include media-breakpoint-up(md) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 168px;
      padding-bottom: 168px;
    }

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }

  }

  .sub-title {
    color: $brand;
    font-family: $font-secondary;
    font-size: 28px;
    opacity: 0;
    transform: translateY(15px);
    animation: heroHomeFadeIn 0.8s ease-in-out forwards;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      font-size: 40px;
    }

  }

  .title {
    font-size: 30px;
    letter-spacing: 0.05em;
    line-height: 41px;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 0;

    opacity: 0;
    transform: translateY(15px);
    animation: heroHomeFadeIn 0.8s ease-in-out forwards;
    animation-delay: 0.1s;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 55px;
      max-width: 50%;
    }

    @include media-breakpoint-up(xxxl) {
      max-width: 35%; 
    }

    span {
      color: $brand;
      font-family: $font-secondary;
      font-size: 35px;
      text-transform: capitalize;

      @include media-breakpoint-up(md) {
          font-size: 55px;
      }

      @include media-breakpoint-up(xxl) {
          font-size: 95px;
      }
    }
  }
}