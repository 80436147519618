/* ==========================================================================
   
   PAGE

========================================================================== */

.page-section {

  margin-bottom: 50px;

  @include media-breakpoint-up(lg) {

    margin-bottom: 100px;

  }

  &.section--sm {

    margin-bottom: 25px;

    @include media-breakpoint-up(lg) {

      margin-bottom: 50px;

    }

  }

}

.section-row {

  width: calc(100% + 10vw);
  margin-left: -5vw;
  margin-right: -5vw;

}

.section-content {

  &.content--fullwidth {

    p {

      @include media-breakpoint-up(md) {
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
      }
      
    }

  }

  &.content--center {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  .title {
    margin-bottom: 20px;
    color: $heading-color;
  }

  .sub-title {
    margin-bottom: 20px;
    color: $heading-color;
  }

  .btn {
    margin-top: 25px;

    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }
    
  }

}