// Global
body {

  background: $white;
  color: $text-color;

  font-family: $font-base;

  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  -webkit-text-size-adjust:100%;

  // fixed header
  margin-top: 144px;

  @include media-breakpoint-up(xl) {

    margin-top: 100px;

  }

}

.container-fluid {

  max-width: 1800px;
  padding-left: 5vw;
  padding-right: 5vw;
  
}

.row-large {

  margin-left: -30px;
  margin-right: -30px;

  > [class*='col-'] {

    padding-left: 30px;
    padding-right: 30px;

  }

}

.wrap {

  position: relative;
  overflow: hidden;

}

img {

  max-width: 100%;
  height: auto;
  
}

/* HEADINGS
========================================================================== */
#{headings(1,6)} {

  color: $heading-color;

}

h1,
.h1 {

  font-size: 40px;

}

h2,
.h2 {

  font-size: 36px;

}

h3,
.h3 {

  font-size: 20px;
  margin-bottom: 15px;

  @include media-breakpoint-up(md) {

    font-size: 28px;
    margin-bottom: 20px;
    
  }

}

h4,
.h4 {

  font-size: 22px;

  @include media-breakpoint-up(md) {

    font-size: 26px;

  }

}

.aboveline {

  position: relative;

  &:before {

    content: '';
    width: 60px;
    height: 1px;
    background: $brand;
    margin-bottom: 20px;
    display: block;

  }

  &.aboveline--center {
    
    &:before {

      @include media-breakpoint-up(md) {

        margin-left: auto;
        margin-right: auto;
        
      }
      
    }

  }

}

/* PARAGRAPH
========================================================================== */
a {

  text-decoration: none;

  &:hover {

    text-decoration: none;

  }

}

p {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:empty {
    display: none;
  }

}

