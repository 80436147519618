/* ==========================================================================
   
   FOOTER

========================================================================== */

footer.primary {

	background: $heading-color;
	padding: 30px 0;
	overflow: hidden;

	@include media-breakpoint-up(lg) {

		padding: 50px 0;

	}

	.container-fluid {

		padding-left: 2vw;
		padding-right: 2vw;

	}

	p, a {

		color: $white;

	}

	p {

		@include media-breakpoint-down(md) {

			font-size: 11px;

		}

	}

	.footer-logo {

		position: relative;
		height: 100%;
		margin-bottom: 30px;

		@include media-breakpoint-up(xl) {

			&:before {

				content: '';
				background: $red;
				width: 1px;
				height: 100%;
				position: absolute;
				top: 0;
				right: 20px;
				bottom: 0;

			}
			
		}

		.logo {

			width: 140px;

		}

	}

	.footer-nav {

		margin-bottom: 30px;

		@include media-breakpoint-up(xl) {

			margin-bottom: 0;
			
		}	

		li {

			&:not(:last-child) {

				margin-bottom: 15px;

				@include media-breakpoint-up(xl) {

					margin-bottom: 30px;
					
				}

			}

			a {

				display: block;

			}

		}

	}

	.footer-about {

		@include media-breakpoint-up(xl) {

			padding-left: 100px;

		}

	}

	.copyright {

		opacity: 0.5;
		border-top: 1px solid #6E6E6E;
		margin-top: 20px;
		padding-top: 20px;

		@include media-breakpoint-up(lg) {

			padding-top: 40px;
			margin-top: 0;

		}

	}

}