.block-image-cta {

  align-items: center;

  &.image-cta--right {

    .image {
      width: 90%;
      margin-left: 10%;
      
      @include media-breakpoint-up(md) {
        order: 2;
        margin-left: auto;
        margin-right: -5vw;
        width: 100%;
      }
      
    }

    .content {
      @include media-breakpoint-up(md) {
        order: 1;
        margin-right: 5vw;
        margin-left: auto;
      }
    }
  }

  &.image-cta--alt {

    .image {
      width: 90%;
      
      @include media-breakpoint-up(md) {
        width: calc(50% + 5vw);
      }   

    }

    .content {
      
      padding-left: 10vw;
      padding-right: 10vw;

      @include media-breakpoint-up(md) {

        width: calc(50% - 5vw);

        padding-left: 5vw;
        padding-right: 5vw;
        
      }


    }

  }

  .image {

    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {

      margin-left: -5vw;
      margin-bottom: 0;
      
    }

  }

  .content {

    @include media-breakpoint-up(lg) {

      margin-left: 5vw;
      
    }

    @include media-breakpoint-only(xl) {

      max-width: 510px;
      
    }

    .title {
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }

  }

  &.image-cta--download {

    .content {
      width: 100%;
      margin-bottom: 60px;

      @include media-breakpoint-up(lg) {
        width: calc(50% - 5vw);
        margin-bottom: 0;
      }
    }

    .image {

      background: $red;
      height: 480px;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: calc(100% + 5vw);
      margin-left: 0;

      @include media-breakpoint-up(lg) {
        width: calc(50% - 5vw);
      }

      &:before {

        content: '';

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: translateY(-50%) scale(1);
        transform-origin: left;
        margin: 0 auto;

        width: 0; 
        height: 0; 
        border-left: 40px solid transparent;
        border-right: 40px solid transparent;

        border-top: 40px solid $white;
        transition: transform .6s cubic-bezier(0.42, 0, 0, 0.95);

        @include media-breakpoint-up(md) {
          transform: translateY(-50%) scale(1) translateX(-20px);
        }

        @include media-breakpoint-up(xl) {
          top: 50%;
          left: 0;
          right: auto;
          transform: translateY(-50%) scale(0.03);

          border-top: 700px solid transparent;
          border-bottom: 700px solid transparent;
          border-right: none;
          border-left: 700px solid $white;
        }
      }

      &:hover {

        &:before {

          @include media-breakpoint-up(xl) {

            transform: translateY(-50%) scale(1);
            
          }

          @include media-breakpoint-up(xxxl) {

            transform: translateY(-50%) scale(1.1);

          }

        }

        .download-text {

          h4 {

            @include media-breakpoint-up(xl) {

              color: $brand;
              transition: color 0.4s ease-in-out 0.2s;

            }

          }

        }

      }

      .download-content {

        padding-left: calc(5vw + 30px);
        padding-right: calc(5vw + 30px);
        text-align: center;

        @include media-breakpoint-up(md) {

          margin-left: -5vw;

        }

      }

      .download-text {

        position: relative;
        display: block;

        h4 {

          color: $white;
          font-size: 40px;
          transition: color 0.4s ease-in-out;
          
        }

        &:before {

          content: '';

          background: url('../images/download-arrow.svg') no-repeat;
          background-size: contain;
          background-position: center;

          position: absolute;
          top: -120px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 45px;
            height: 92px;

        }

        &:after {

          content: '';

          background: url('../images/download.svg') no-repeat;
          background-size: contain;
          background-position: center;

          position: absolute;
          top: auto;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 75px;
            height: 50px;
            transform: translateY(20px);

        }

        &:hover {

          &:before {


          }

          &:after {
            


          }

        }

      }

    }

  }

}

.block-image-cta--large {

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 70px;

  &:before {

    content: '';

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.92);

  }

  @media (min-width: 1800px) {

    margin-left: -5vw;
    margin-right: -5vw;

  }

  .number {

    position: absolute;
    top: -16px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;

  }

  .content {

    padding: 50px 5vw;

    @include media-breakpoint-up(md) {

      text-align: center;
      
    }

    @include media-breakpoint-up(lg) {

      padding: 250px 100px;

    }

    &:after {

      @include media-breakpoint-up(lg) {

        content: '';

        position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 1px;
          height: 70%;
          
        background: rgba(#6E6E6E, 0.2);
          margin: auto;
        
      }

    }

    p {

        @include media-breakpoint-down(md) {

          font-size: 15px;

        }

      }

      .content-left {

        position: relative;

        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {

          padding-right: 50px;
          margin-bottom: 0;

        }

        @include media-breakpoint-up(xxl) {

          padding-right: 100px;

        }

      }

      .content-right {

        @include media-breakpoint-up(lg) {

          padding-left: 50px;

        }

        @include media-breakpoint-up(xxl) {

          padding-left: 100px;

        }

      }

  }

}

.number {
  font-size: 26px;
  font-family: $font-secondary;
  color: $heading-color;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    margin-bottom: 30px;
  }

  > span {
    color: $brand;
    font-size: 40px;

    @include media-breakpoint-up(md) {
      font-size: 60px;
    }

  }

}