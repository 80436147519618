/* ==========================================================================
   
   BUTTONS

========================================================================== */

.btn,
button {

    cursor: pointer;
    position: relative;
    display: inline-block;

    background: $brand;
    color: $white;
    font-size: 15px;
    font-weight: $font-semibold;
    white-space: normal;

    padding: 18px 40px;
    transform: skew(-25deg) scale(1);
    border: none;
    border-radius: 0;
    overflow: hidden;

    transition: transform 0.2s ease-in-out;

    @include media-breakpoint-up(md) {

      font-size: 18px;

    }

    > span {

      transform: skew(25deg);
      display: block;
      line-height: 22px;

    }

     &.btn--grey {

      background: lighten($darkgrey, 35%);
      transition: all 0.2s ease-in-out;

      &:hover {

          background: $brand;
          opacity: 1;

      }
        
    }

    &.btn--fullwidth {

        width: 100%;
        
    }

    &.btn--mobile-special {
      @include media-breakpoint-down(sm) {
        margin-left: -10vw;
        padding-left: 10vw;
        text-align: left;
        width: 100%;
      }
    }

    .underline {

      text-decoration: underline;
      font-weight: $font-bold;

    }

    &[disabled] {

      background: $darkgrey;
      opacity: 0.5

    }

    &:hover {

      color: $white;

      &:before {

          transform: translate(0);
          opacity: 1;

      }

    }

    &:active {

      transform: skew(-25deg) scale(0.97);

    }

    &:focus {

      box-shadow: none;

    }

}

.btn-skew {
  transform: skew(25deg);
}