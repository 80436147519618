.block-accordion {

	background: #F5F5F5;
	padding: 25px;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-up(lg) {

		padding: 50px;

	}

	.accordion-item {

		border-bottom: 1px solid rgba(#2E2E2F, 0.1);
		padding-top: 30px;
		padding-bottom: 13px;

		&:first-child {

			padding-top: 0;

			.item-content {

				display: block;

			}

		}

		&.active .item-title {

			&:after {

				transform: translateY(-50%) rotate(225deg);
				transform-origin: right;
				border-color: rgba(0, 0, 0, 0.5);

			}
		}

		.item-title {
			
			margin-bottom: 20px;
			display: block;
			position: relative;

			&:after {

				content: '';
				border: solid $brand;
			    border-width: 0 3px 3px 0;
			    display: inline-block;
			    padding: 8px;
			    transform: rotate(45deg) translateY(-50%);

			    position: absolute;
			    right: 25px;
			    top: 28%;
				
				transform-origin: 100% 100%;
			    transition: all 0.3s ease-in-out;

			    @include media-breakpoint-up(md) {

			    	right: 50px;

			    }

			}

		    h4 {

		    	max-width: calc(100% - 40px);

		    }

		}

		.item-content {

			display: none;
			margin-bottom: 20px;
			@extend .aboveline;

			&:before {

				margin-bottom: 30px;

			}

			h5 {

				font-weight: 600;
				margin-bottom: 30px;

			}

		}

	}

}