/* ==========================================================================
   
   PIE SECTION

========================================================================== */

.section-pie {

  .section-content {

    margin-bottom: 45px;

    @include media-breakpoint-up(md) {

      margin-bottom: 90px;

    }
    
  }

}

.section-video {

  position: relative;
  overflow: hidden;

  &.video-bg {

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

  }

  .overlay-banner {

    position: relative;
    @include z-index(videoEmbedBanner);
    text-align: center;
    margin-bottom: -30px;
    margin-left: -10vw;

    @include media-breakpoint-up(md) {
      width: calc(100% - 10vw);
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: -27px;
      width: auto;
    }

    .btn {
      @include media-breakpoint-down(sm) {
        width: 90%;
        margin-left: -10vw;
      }

    }

  }

  .overlay-content {

    position: static;
    top: 27px;
    left: 0;
    @include z-index(videoEmbedTop);

    background: rgba(255, 255, 255, 0.92);
    width: 100%;
    height: 100%;
    padding: 0 20px 10px 20px;

    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    display: none;

    @include media-breakpoint-up(md) {

      position: absolute;
      top: 27px;
      display: block;
      overflow: scroll;
      padding: 50px 100px 130px 100px;

    }

    @include media-breakpoint-up(lg) {
      padding: 130px 100px;
    }

    @include media-breakpoint-up(xl) {
      overflow: hidden;
    }

    &.active {
      @include media-breakpoint-down(sm) {
        margin-top: 60px;
        margin-bottom: 20px;
      }
    }

    &.active,
    &.show-content {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.6s;
      display: block;
    }

    &.show-content {
      position: static;
    }

    &.content-half {

      p {

        @include media-breakpoint-down(md) {

          font-size: 15px;

        }

      }

      .content-left {

        position: relative;

        @include media-breakpoint-up(lg) {

          padding-right: 50px;

          &:after {

            content: '';

            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;

            width: 1px;
            height: 100%;
            background: rgba(#6E6E6E, 0.2);

          }

        }

        @include media-breakpoint-up(xxl) {

          padding-right: 100px;

        }

      }

      .content-right {

        @include media-breakpoint-up(lg) {

          padding-left: 50px;

        }

        @include media-breakpoint-up(xxl) {

          padding-left: 100px;

        }

      }

    }

  }

}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: 400px;
  background: #000;

  @include media-breakpoint-up(md) {
    height: auto;
    background: none;
  }
}

.embed-container iframe,
.embed-container object,
.embed-container embed,
.embed-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 350px;;
  @extend .d-md-block;

  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.embed-wrap {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  @include z-index(videoEmbedWrap);
  @extend .d-md-block;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
  }

  &.fade-out {
    transition: all 0.3s ease-in-out 0.4s;
    opacity: 0;
    visibility: hidden;
  }

  .overlay-banner {
    position: absolute;
    @include z-index(videoEmbedOverlay);

  }

  .embed-content {
    position: absolute;
    top: 40%;
    left: 5vw;
    @include z-index(videoEmbedOverlay);
    transform: translateY(-50%);

    color: white;
    max-width: 80%;

    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in-out 0.4s;

    @include media-breakpoint-up(md) {
      top: 50%;
      max-width: 420px;
    }

    @include media-breakpoint-up(xxxl) {
      left: 10vw;
    }

    &.fade-out {
      transition: all 0.3s ease-in-out 0.2s;
      opacity: 0;
      visibility: hidden;
      transform: translateY(calc(-50% - 30px));
    }

    .title {
      font-size: 26px;
      font-weight: $font-semibold;
      color: $white;
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 15px;

      @include media-breakpoint-up(md) {
        font-size: 40px;
        line-height: 55px;
      }

    }

  }

  .embed-video-play {

    position: absolute;
    display: inline-block;
    height: 56px;
    @include z-index(videoEmbedPlay);

    position: absolute;
    top: 70%;
    left: 10vw;
    transform: translateY(-50%);

    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in-out 0.6s;

    @include media-breakpoint-up(md) {

    top: 50%;
      right: 33vw;
      left: auto;

    }

     &.fade-out {

      transition: all 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
      transform: translateY(calc(-50% - 30px));

    }

    a {

      display: block;
      width: 40px;
      height: 56px;
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;

      @include media-breakpoint-up(md) {

        transition: transform 0.3s cubic-bezier(0.42, 0, 0, 0.95);

        &:hover {
          transform: scale(1.4);
        }
      }

      &:before {

        content: '';
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.5);
        @include z-index(videoEmbedWrap);

        background: url('../images/play.svg') no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        margin-left: 3px;

        @include media-breakpoint-up(md) {
          transform: translate(-50%, -50%);
          margin-left: 6px;
        }

      }

      &:after {

        content: '';
        border: 5px solid white;
        border-radius: 100%;
        width: 100%;
        width: 60px;
        height: 60px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;

        @include media-breakpoint-up(md) {
          border: 10px solid white;
          width: 120px;
          height: 120px;
        }

      }

    }

  }

}