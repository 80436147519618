// e.g '@include z-index(header)' on element;

$z-index: (
  header		: 1000,
  heroGradient  : 50,
  heroInner		: 60,
  aboveHero		: 100,
  videoEmbedWrap	: 1,
  videoEmbedPlay	: 5,
  videoEmbedOverlay	: 10,
  videoEmbedTop  	: 15,
  videoEmbedBanner	: 20,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}