/* ==========================================================================
   
   COLORS

========================================================================== */

$brand: #E4032E;
$red: $brand;
$black: #212121;
$white: #fff;
$grey: #F5F5F5;
$darkgrey: #707070;
$red: $brand;


/* ==========================================================================
   
   TYPE

========================================================================== */

$heading-color: #2E2E2F;
$text-color: #6E6E6E;

$font-base: 'Avenir', sans-serif;
$font-secondary: 'Baskerville-Italic', serif;
$font-avenir: $font-base;
$font-baskerville: $font-secondary;

$font-regular: 400;
$font-semibold: 500;
$font-bold: 700;
$font-heavy: 900;

$spacing: 20px;
$spacing-half: $spacing * 0.5;
$spacing-double: $spacing * 2;

$header-height-mobile: $spacing * 3;
$header-height-desktop: $spacing * 3;


/* ==========================================================================
   
   GRID

========================================================================== */

$site-width: 1800px;
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);
