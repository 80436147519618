/* ==========================================================================
   
   CALCULATOR - MODELLER

========================================================================== */

.section-modeller {

  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;

  transition: padding 0.5s ease-in-out, background 0.5s ease-in-out 0.2s;

  &.active {
    padding-top: 50px;
    padding-bottom: 100px;
    background: $grey;

    .section-content {
      padding-bottom: 70px;
    }

  }

  &.results-active {
    padding-bottom: 25px;

    .section-content {
      padding-bottom: 50px;
      margin-bottom: 0;
    }
    
  }

  .section-content {
    position: relative;
    padding-bottom: 100px;
    transition: padding 0.5s ease-in-out;

    &:before {
      content: '';
      background: rgba($text-color, 0.5);
      position: absolute;
      bottom: 0;
      right: 0;
      left: -125px;
      height: 1px;
      width: calc(100% + 250px)
    }

  }

  .open-modeller {
    transition: all 0.5s ease-in-out;

    &.hide {
      opacity: 0;
      visibility: hidden;
      margin-top: 0;
    }

  }

  .amend-modeller {
    display: none;

    &.active {
      display: inline-block;
    }

  }

  .contact-cta {
    transition: margin 0.3s ease;
    margin-left: -25px;
    margin-right: -25px;

    &.active {
      @include media-breakpoint-up(lg) {
        margin-left: -50px;
        margin-right: -50px;
      }

      @include media-breakpoint-up(xl) {
        margin-left: -125px;
        margin-right: -125px;
      }
      
    }


  }

}

// Modeller Form
.form-modeller {
  display: none;

  .form-fields-wrap {
    overflow: hidden;
  }

  .row-large {
    position: relative;
    margin-bottom: 50px;

    &.row-border {
      border-bottom: 1px solid rgba($text-color, 0.3);
    }

  }

  .form-group {
    span {
      margin-top: 10px;
      display: inline-block;
    }
  }

  .modeller-submit {
    margin-top: 50px;
  }

  .currency-wrap {
    position: relative;
    max-height: 50px;

    &:before {
      content: '\00a3';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      font-size: 18px;
      color: $text-color;
    }

    input {
      padding-left: 40px;
    }

  }

  input {

    &.error {
      border-color: $brand;
    }

  }

}

.form-results {
  display: none;

  .contact-cta {
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;

    @include media-breakpoint-up(md) {
      padding-left: 50px;
      padding-right: 50px
    }

  }

  .form-fields-wrap {

    @include media-breakpoint-up(lg) {
      margin-left: 50px;
      margin-right: 50px;
    }

    .row-border {
      border-bottom: 1px solid rgba($white, 0.3);
      margin-bottom: 50px;
    }

  }

  .form-group {

    span {
      margin-top: 10px;
      display: inline-block;
    }

    .inflation-loading {
      display: none;
    }

  }

}

.result-graph {
  color: $text-color;

  .graph-tabs {

    @extend .list-unstyled;
    text-align: center;
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 60px;
    }

    li {
      display: inline-block;

      &:first-child {
        margin-right: 30px;
      }

      a {
        font-size: 14px;
        text-align: center;
        margin-bottom: 30px;

        @include media-breakpoint-up(sm) {
          font-size: 18px;
          margin-bottom: 60px;
        }

        &.current {

          position: relative;
          border-bottom: 1px solid $brand;
          padding-bottom: 3px;

          &:after {
            content: '';
            position: absolute;
            top: 16px;
            right: 0;
            left: 0;
            margin: 0 auto;
              
            width: 20px;
            border-top: 20px solid transparent;
            border-bottom: 20px solid #fff;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;

            @include media-breakpoint-up(sm) {
              top: 46px;
            }

          }

        }
        
      }

    }

  }

  .graphs {
    background: $white;
    padding: 50px 30px;
    margin-bottom: 50px;
    overflow: hidden;

    .graph {
      width: 100%;
      min-height: 300px;

      @include media-breakpoint-up(md) {
        min-height: 600px;
      }

    }

    .google-chart {
      width: 100%;
      min-height: 300px;

      @include media-breakpoint-up(md) {
        min-height: 600px;
      }

    }

  }

}

.form-result-box {

  background: white;
  text-align: center;
  color: $text-color;
  padding: 25px;
  height: 100%;

  @include media-breakpoint-up(lg) {
    padding: 50px;
  }

  .title {
    padding-bottom: 30px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba($text-color, 0.4);

    @include media-breakpoint-up(md) {
      padding-bottom: 50px;
      margin-bottom: 45px;
    }

  }

  .result {

    position: relative;

    &.first {
      &:after {
        content: '';
        background: rgba($brand, 0.5);
        width: 50px;
        height: 1px;
        display: inline-block;
        margin: 20px auto 15px auto;

        @include media-breakpoint-up(lg) {
          margin: 35px auto 30px auto;
        }

      }
      
    }

    p:not(.large) {
      margin-bottom: 15px;
    }

    .large {
      font-size: 28px;
      color: $heading-color;

      @include media-breakpoint-up(lg) {
        font-size: 40px;
      }

    }
    
  }

}

.future-inflation-grid {
  background: $white;
  border-left: 1px solid lighten($heading-color, 10%);
  border-right: 1px solid lighten($heading-color, 10%);
  border-bottom: 1px solid lighten($heading-color, 10%);
  margin-bottom: 50px;

  .grid-row {
    display: grid;
    grid-template-columns: 40% 30% 30%;

    &:nth-child(odd) {
      background: $grey;
    }

  }

  .grid-item {
    text-align: center;
    padding: 15px;

    &:first-child { -ms-grid-column: 1; }
    &:nth-child(2) { -ms-grid-column: 2; }
    &:last-child { -ms-grid-column: 3; }

    &.item-header {

      background: $heading-color;
      color: $white;
      padding: 5px;
      border: 1px solid lighten($heading-color, 10%);

      @include media-breakpoint-up(sm) {
        padding: 15px;
      }

      span {
        font-size: 12px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
        
      }

    }

  }

}

.disclaimer {

  .title {
    text-align: center;
    font-weight: bold;
  }

  p:not(.title) {
    font-size: 14px;
  }

  .modeller-cta {
    position: relative;
    top: 50px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;

    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: 0;
    }

    span span {
      font-weight: bold;
    }

    .btn .btn-skew {
      margin-left: auto;
      margin-right: auto;
    }
    
    /* WYSIWYG */
    p {
      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }

    a {
      color: #fff;
    }

  }

}