/* ==========================================================================
   
   HEADER

========================================================================== */

header.primary {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @include z-index(header);
  background: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  padding-left: 2vw;

  @include media-breakpoint-up(xl) {

    padding-right: 0;
    
  }

  .primary-main {

    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    height: 44px;

    @include media-breakpoint-up(xl) {

      position: static;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      
    }

  }

  .logos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-up(xl) {
      width: auto;
      flex: 1 0 auto;
    }

    img.primary {
      width: 115px;
    }

    .company-logo {
      position: relative;
      margin-left: 30px;
      padding-left: 30px;

      &:before {
        content: '';
        width: 1px;
        height: 60px;
        background: $brand;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

      }

      img {
        max-height: 50px;
      }

    }

  }

  .contact {
    height: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: $brand;
    display: flex;

    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    @include media-breakpoint-up(xl) {
      width: 190px;
      opacity: 1;
      visibility: visible;
      margin-left: 60px;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }


    a {
      display: block;
      background: $brand;
      color: $white;
      font-size: 14px;
    }

  }

  .navigation {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    padding-top: 30px;
    background: $white;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 0;
      opacity: 1;
      visibility: visible;
      margin-left: auto;
    }

  }

  .open-menu {
    display: block;
    background: $brand;
    color: $white;
    padding: 10px;
    text-align: center;

    @include media-breakpoint-up(xl) {
      display: none;
      height: 0;
      visibility: hidden;
    }

    &.active .menu-icon:before {
      width: 25px;
    }

    .menu-icon {

      position: relative;
      top: 2px;
      border-bottom: 2px solid $white;
      border-top: 2px solid $white;
      width: 25px;
      display: inline-block;
      margin-right: 5px;
      padding-bottom: 12px;

      &:before {
        content: '';
        width: 20px;
        height: 2px;
        background: $white;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        transition: width 0.3s ease-in-out;
      }

    }

  }

}

// Fix for poor font rendering in IE
_:-ms-lang(x), .menu-item .menu-link {
  font-family: Arial;
}

.navigation {

  .menu {
    @extend .list-unstyled;
    text-align: center;
    margin-bottom: 0;

    @include media-breakpoint-up(xl) {
      margin-left: 70px;
      text-align: left;
    }

    @include media-breakpoint-up(xxl) {
      margin-left: 150px;
    }

    li {
      margin-bottom: 20px;

      &:last-child {
        padding-bottom: 30px;
        margin-bottom: 0;
      }

      @include media-breakpoint-up(xl) {
        display: inline-block;
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: 30px;
        }

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
        
      }

      a {
        font-size: 14px;
        font-weight: $font-bold;
        color: $black;
        display: block;
      }

    }

  }
  
}