/* ==========================================================================
   
   CONTACT

========================================================================== */

.contact-form-wrap {
  background: $grey;
  padding: 25px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    padding: 50px;
  }

  .section-content {
    margin-bottom: 50px;
  }
  
  .message {
    margin-bottom: 50px;

    &--sm {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
    }

  }

  &.contact-form-basic form {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }

}

.contact-cta {
  position: relative;
  background: $heading-color;
  padding: 30px 20px;
  margin-bottom: 50px;
  text-align: center;
  color: $white;

  margin-left: -25px;
  margin-right: -25px;

  @include media-breakpoint-up(md) {
    margin-left: -50px;
    margin-right: -50px;
  }

  @include media-breakpoint-up(lg) {
    padding: 20px;
    margin-left: -100px;
    margin-right: -100px;
  }

  &:before {

    @include media-breakpoint-up(md) {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      background: #444;
      width: 1px;
      height: 100%;
    }

  }

  &.cta--large {

    &:before {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      padding: 38px 20px;
    }

  }

  p {
    margin-bottom: 0;
  }

  a {
    color: $white;
  }

  .tel {
    font-size: 40px;
    letter-spacing: 0.1em;
    line-height: 40px;

    margin-top: 15px;
    margin-bottom: 15px;

    display: inline-block;
  }

  .email {
    border-bottom: 1px solid $red;
    padding-bottom: 2px;
    font-size: 18px;
    margin-top: 30px;
    display: inline-block;

    @include media-breakpoint-up(md) {

      margin-top: 0;

    }

  }

}

form,
.form-style {

  label {

    font-size: 18px;
    margin-bottom: 10px;

  }

  input {

    height: 50px;
    background: $white;
    border: none;
    width: 100%;

    font-size: 18px;
    color: $black;
    padding-left: 20px;
    padding-right: 20px;

    border: 2px solid $white;
    transition: border 0.2s ease-in-out;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: lighten($darkgrey, 35%);
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: lighten($darkgrey, 35%);
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: lighten($darkgrey, 35%);
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: lighten($darkgrey, 35%);
    }

    &:focus {
      outline: none;
      border-color: rgba($black, 0.5);

      & ~ .input-close {
        opacity: 1;
        visibility: hidden;
      }

    }

    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }


  }

  input[type="number"] {

    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }

  .input-wrap {

    position: relative;

    .input-close {

      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);

      font-family: $font-base;
      font-size: 26px;
      font-weight: bold;
      color: $black;
      display: inline-block;

      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease-in-out;

      &.active {
        opacity: 1;
        visibility: visible;
      }

    }

  }

  .wpcf7-not-valid-tip {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }

  .wpcf7-response-output {
    border: none;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .form-group {
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
      margin-bottom: 50px; 
    }

  }

  .list-checkbox {

    @extend .list-unstyled;
    margin-bottom: 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;


    li {
      @extend .btn;
      padding: 10px 25px;
      background: lighten($heading-color, 50%);
      transition: background 0.2s ease-in-out;
      margin-bottom: 10px;

      @include media-breakpoint-up(lg) {
        padding: 10px 40px; 
      }

      &.active,
      &:hover {
        background: $red;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      > p {

        transform: skew(25deg);
        display: block;

        span {
          display: block;
        }

      }

    }

  }

}

.block-banner {
  background: $grey;
  padding: 100px 20px;

  .banner-content {
    max-width: 505px;
    margin-left: auto;
    margin-right: auto;
  }
}