.page-404 {
  padding-top: $spacing * 5;
  padding-bottom: $spacing * 5;

  @include media-breakpoint-up(md) {
    padding-top: $spacing * 7.5;
    padding-bottom: $spacing * 7.5;
  }

  .title {
    margin-bottom: $spacing-double;
  }

  .title-secondary {
    margin-bottom: $spacing-double;
    border-bottom: 1px solid rgba($text-color, 0.3);
    padding-bottom: $spacing-double;
  }
}

.page-template-template-home,
.error404 {
  header.primary {
    padding-left: 0;

    .primary-main {
      display: none;
    }
  }
}