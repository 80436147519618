/* ==========================================================================
   
   ANIMATE

========================================================================== */

// Hero BG Fade/move
@keyframes heroBg {

	100% {

		transform: translateX(0);
		opacity: 1;

	}

}

// Hero Home Fade In
@keyframes heroHomeFadeIn {

	100% {

		opacity: 1;
		transform: translateY(0);
		
	}

}